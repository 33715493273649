<template>
  <div>
    <b-modal hide-footer  size="xl" id="driversPriorityWindow" title="Setează prioritate conform interval de timp" centered  ref="driversPriorityWindow">
      <div class="inner">
        <b-row>
          <b-col md="6">
            <div>
              <b-button size="sm" @click="setTimeInterval(1)" class="mr-2">1 ora</b-button>
              <b-button size="sm" @click="setTimeInterval(2)" class="mr-2">2 ore</b-button>
            </div>
            <b-form-group label="Interval timp:" >
              <b-input-group size="sm">
                <datePicker v-model="users.from" :config="options" placeholder="Data de la:"></datePicker>
                <datePicker v-model="users.to" :config="options" placeholder="Data până la:"></datePicker>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Prioritate șoferi:" >
              <UserSelect  v-model="users.users" :multiple="true" :roles="['driver']"></UserSelect>
            </b-form-group>
            <b-button size="sm" variant="success" @click="save" > <b-icon icon="file-earmark-check"></b-icon> Salvează</b-button>
          </b-col>
          <b-col md="6">
            <div>
              Intervale precedente:
              <b-card no-body class="my-1 px-1 py-1" v-for="item in intervals" :key="item.id" >
                <div class="d-flex justify-content-between align-items-center">
                  <b-button size="sm" variant="success"  :id="'popover-target-' + item.id">
                    <b-icon icon="list"></b-icon> - șoferi
                  </b-button>
                  <strong class="mr-auto ml-3 ">{{ item.from }} - {{ item.to }}</strong>
                  <b-button size="sm" variant="danger" @click="remove(item.id)">x</b-button>
                </div>
                  <b-popover :target="'popover-target-' + item.id" triggers="hover" placement="left">
                    <b-badge class="mx-2" variant="light" v-for="user in item.users" :key="user.id">{{ user.indicativ }}</b-badge>
                  </b-popover>

              </b-card>
    <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
    >
    </b-pagination>
            </div>
          </b-col>
        </b-row>

        <div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import config from '@/config'
import UserSelect from '@/components/common/UserSelect'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import dayjs from 'dayjs'

export default {
  name: 'DriversPriority',
  components: {
    UserSelect,
    datePicker
  },
  mounted () {
    this.get()
  },
  data () {
    return {
      perPage: 10,
      currentPage: 1,
      usersData: '',
      totalRows: 0,
      intervals: null,
      driversPriority: null,
      users: {
        from: null,
        to: null,
        users: []
      },
      date: new Date(),
      options: {
        format: 'YYYY-MM-DD HH:mm:ss',
        useCurrent: false
      }
    }
  },
  watch: {
    currentPage: function () {
      this.get()
    }
  },
  computed: {
    rows () {
      return this.totalRows
    },
    driverIds: function () {
      let driverSet = []
      return this.users.users.map((driver) => {
        driverSet = {
          id: driver.id,
          indicativ: driver.default_car ? driver.default_car.indicativ : 'XXX'
        }
        return driverSet
      })
    }
  },
  methods: {
    get () {
      this.$axios.get(config.baseApiUrl + '/api/users-intervals?page=' + this.currentPage + '&per_page=' + this.perPage)
        .then((response) => {
          this.intervals = response.data.data
          this.totalRows = response.data.total
          // console.log(this.totalRows)
        })
    },
    save () {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/users-intervals',
        data: Object.assign({}, this.users, { users: this.driverIds })
      })
        .then((response) => {
          this.$toasted.success('Prioritate salvată!', {
            duration: 3000
          })
          this.users.from = null
          this.users.to = null
          this.users.users = []
          this.get()
        })
    },
    remove (id) {
      this.$axios({
        method: 'delete',
        url: config.baseApiUrl + '/api/users-intervals/' + id
      })
        .then((response) => {
          this.$toasted.success('Prioritate eliminată!', {
            duration: 3000
          })
          this.get()
        })
    },
    show () {
      this.$refs.driversPriorityWindow.show()
    },
    setTimeInterval (hours) {
      this.users.from = dayjs().format('YYYY-MM-DD HH:mm:ss')
      this.users.to = dayjs().add(hours, 'hour').format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped>
</style>
