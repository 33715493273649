<template>
  <div>
    <div v-if="$store.state.isGoogleApiLoaded" class="position-relative">
      <div class="driverSearch" style="z-index: 2000;">
        <p>Caută după indicativ: </p>
        <b-form-input autocomplete="off"  class="driverSearchIndicativ" placeholder="___" v-model="markerSearch"></b-form-input>
        <b-button class="ml-2" size="sm" variant="primary" @click="togglePoly">Afișează zonele</b-button>
      </div>
      <div class="addressSearch px-2 ml-1" style="z-index: 2000;">
        <vue-autosuggest
          style="flex: 1"
          ref="select"
          v-model="addressSearch.addressSearchField"
          :suggestions="[{ data: addressSearch.addressSearchResults }]"
          @selected="onAddressSearchSelected"
          @input="onAddressSearch"
          :get-suggestion-value="(s) => s.item.label"
          :input-props="{id:'autosuggest__input', style: 'width:100%;height: 34px;border: 1px solid #ccc;padding: 0 10px', placeholder:'Introduce-ți adresa (ex.: 13 Calea București)'}">
          <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
            <div style="width:100%; display: flex; justify-content: space-between;">
              {{ formatName(suggestion.item) }}
            </div>
          </div>
        </vue-autosuggest>
        <b-btn size="sm" class="ml-2" @click="clearAddressSearch">CLEAR</b-btn>
      </div>
      <div class="mapSwitcher pl-1">
        <b-select v-model="useGoogleMaps" size="sm">
          <b-select-option :value="true">GOOGLE</b-select-option>
          <b-select-option :value="false">OPENSTREET</b-select-option>
        </b-select>
      </div>
      <template v-if="driversLocations">
        <GmapMap
          v-if="useGoogleMaps"
          ref="map"
          :center="this.$store.getters['auth/loggedUser'].id !== 1 ? mapCenter : { lat: 45.9442858, lng: 25.0094303 }"
          :zoom="mapZoom"
          :options="MapStyle"
          style="width: 100%; height: calc(100vh - 100px); border-bottom: 1px solid #bdc1c6"
          :key="''"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen=false"
          >
            {{infoContent}}
          </gmap-info-window>
          <template v-if="showPoly === true">
            <gmap-polygon class="mapPoly" v-for="poly in polygonsGoogleList"
                          v-bind:key="'polygon-' + poly.id"
                          :paths="poly.paths"
                          :editable="false"
                          :options="{fillColor:  fillC, fillOpacity: 0.5,strokeOpacity: 1,strokeWeight: 1, strokeColor: '#fff'}"
                          :draggable="false"
                          @click="showPolygonDesc($event, poly.name)"
                          ref="polygon"
            ></gmap-polygon>
          </template>
          <GmapMarker
            :key="index"
            v-for="(m, index) in driversLocationsFiltered"
            :position="{lat: m.lat, lng: m.long}"

            :clickable="true"
            :icon="markerIcon(m)"
            :draggable="false"
            :label="{color:'#fff', fontSize:'9px',fontWeight:'bold', text: m.indicativ}"
            @click="driverInfo(m.userId)"
          />

          <GmapMarker
            v-if="addressSearch.addressPin"
            key="add-search"
            :position="addressSearch.addressPin"
            :draggable="false"
          />
          <!-- </GmapCluster> -->
        </GmapMap>
        <l-map
          v-else
          style="width: 100%; height: calc(100vh - 100px); z-index: 1;"
          :center="mapCenter"
          :zoom="mapZoom"
        >
          <l-tile-layer
            :url="tilesUrl"
          />
          <l-marker
            v-for="(m, index) in driversLocationsFiltered"
            :key="index"
            :lat-lng="{ lat: m.lat, lng: m.long }"
            @click="driverInfo(m.userId)"
          >
            <l-icon
              class-name="someExtraClass"
            >
              <div v-if="isBanned(m)" :style="{backgroundColor: 'black', color: '#fff', width: '25px', height: '25px', textAlign: 'center', lineHeight: '25px', borderRadius: '50%', fontSize: '10px'}">
                {{ m.indicativ }}
              </div>
              <div v-else-if="m.is_busy " :style="{backgroundColor: 'red', color: '#fff', width: '25px', height: '25px', textAlign: 'center', lineHeight: '25px', borderRadius: '50%', fontSize: '10px'}">
                {{ m.indicativ }}
              </div>
              <div v-else-if="m.is_paused " :style="{backgroundColor: 'orange', color: '#fff', width: '25px', height: '25px', textAlign: 'center', lineHeight: '25px', borderRadius: '50%', fontSize: '10px'}">
                {{ m.indicativ }}
              </div>
              <div v-else :style="{backgroundColor: 'green', color: '#fff', width: '25px', height: '25px', textAlign: 'center', lineHeight: '25px', borderRadius: '50%', fontSize: '10px'}">
                {{ m.indicativ }}
              </div>
            </l-icon>
          </l-marker>
          <l-marker
            v-if="addressSearch.addressPin"
            key="add-search-osm"
            :lat-lng="{ lat: addressSearch.addressPin.lat, lng: addressSearch.addressPin.lng }"
            :draggable="false"
          >
            <l-icon
              class-name="someExtraClass"
            >
              <img :src="mapIco" />
            </l-icon>
          </l-marker>
          <template v-if="showPoly === true">
            <l-polygon
              v-for="poly in polygonsRawList"
              :key="'poly-' + poly.id"
              :lat-lngs="extractPolygonPaths(poly)"
              :color="'#000'"
              :weight="1"
              class="mapPoly"
            >
              <l-popup :content="poly.name" />
            </l-polygon>
          </template>
        </l-map>
      </template>

      <div class="driverInfo" v-show="showDriverInfo" :class="{ showDriver: showDriverInfo }">
        <b-card>
          <b-card-body body-class="p-0">
            <b-card-title>{{ Sofer.identifier ? Sofer.identifier : '' }} <b-button variant="danger" size="sm" class="pull-right" @click="hideDriverInfo"><b-icon icon="x"></b-icon></b-button></b-card-title>
            <b-card-sub-title class="mb-2">{{ Sofer.name }}  </b-card-sub-title>
            <b-card-text>
              <table class="w-100">
                <tr>
                  <td>Balanța: </td>
                  <td>{{ Sofer.balance }} RON</td>
                </tr>
                <tr>
                  <td>Ocupat: </td>
                  <td v-if="Sofer.is_busy" class="text-danger">Da</td>
                  <td v-else class="text-success">Nu</td>
                </tr>
                <tr>
                  <td>Prioritate:</td>
                  <td v-if="Sofer.high_priority">
                    <span class="text-success">Da <b-icon icon="star-fill"></b-icon></span> <b-btn @click="removePriority" class="btn bg-transparent border-white btn-link btn-sm position-relative text-danger" style="top: -1px;">șterge</b-btn>
                  </td>
                  <td v-else>
                    <span class="text-success mr-2">Nu</span>
                    <b-dropdown right text="adaugă" size="sm" class="priority-dropdown">
                      <b-dropdown-item @click="addPriority(1)">1 ora</b-dropdown-item>
                      <b-dropdown-item @click="addPriority(2)">2 ore</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <td>Aplicație:</td>
                  <td>
                    <UserMeta :type="'simple'" :user-id="Sofer.id" />
                  </td>
                </tr>
              </table>
              <div class="mt-2" v-if="Sofer.is_busy">

                <strong>Comenzi active:</strong>
                <b-list-group>
                  <b-list-group-item  class="d-flex px-2 py-1" :to="{ name: 'OperatorOrderView', params: { id: order.id } }" v-for="order in driverHistory" :key="order.id">
                    <span class="mr-2"> #{{ order.id }}: </span>
                    <div v-if="order.places[0].name">
                      <span v-if="order.places[0].name.length >= 35">
                        {{ order.places[0].name.substring(0,35)+".." }}
                      </span>
                      <span v-else>{{ order.places[0].name }}</span>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-card-text>

          </b-card-body>

            <template #footer>
              <b-row>
                <b-button class="ml-3 mr-1" size="sm" variant="secondary" target="_blank" :href="'/user/' + Sofer.id + '/driver'"> Edit <b-icon icon="pencil-square"></b-icon></b-button>
                <UserBanControl v-if="Sofer" :user="Sofer"></UserBanControl>
                <b-button disabled v-b-modal.driverOrders :user-id="Sofer.id" class="mx-1" size="sm" variant="primary"> Istoric comenzi <b-icon icon="list"></b-icon></b-button>
                <b-button @click="openMessages" class="ml-1 mr-3" size="sm" variant="success"> Mesaj <b-icon icon="envelope"></b-icon></b-button>
              </b-row>

            </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import mapIcoFree from '@/assets/driverLocFree.svg'
import mapIcoBusy from '@/assets/driverLocBusy.svg'
import mapIcoPaused from '@/assets/driverLocBan.svg'
import config from '@/config'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-best-google-maps'
import ClickOutside from 'vue-click-outside'
import UserBanControl from '@/components/users/UserBanControl'
import { VueAutosuggest } from 'vue-autosuggest'
import _ from 'lodash'
import store from '@/store'
import UserMeta from '@/components/users/UserMeta'
import { LMap, LTileLayer, LMarker, LIcon, LPolygon, LPopup } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
// eslint-disable-next-line no-unused-vars
import { latLng } from 'leaflet'
import mapIco from '../../../assets/img/location.png'

import dayjs from 'dayjs'
// import GerOrders from '@/components/orders/GetOrders'
// import GmapCluster from 'vue2-best-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})

export default {
  name: 'DriversMap',
  components: { UserMeta, VueAutosuggest, UserBanControl, LMap, LTileLayer, LMarker, LIcon, LPolygon, LPopup },
  data () {
    return {
      polygons: [],
      polygonsRawList: [],
      driversLocations: [],
      driversData: [],
      driverHistory: [],
      driverD: '',
      selected: null,
      optionsSaved: [
        { value: null, text: 'Barbu Lautaru 12' },
        { value: 'a', text: 'Aleea Florilor 7B' }
      ],
      MapStyle: {
        mapTypeControl: false,
        streetViewControl: false
      },
      showDriverInfo: false,
      showPoly: false,
      // InfoWindow
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoContent: '',
      infoWinOpen: false,
      fillC: [
        '#000',
        '#fff'
      ],
      selectedColor: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      Sofer: '',
      getDriverPositionsInterval: null,
      markerSearch: '',
      mapCenter: this.$store.getters['auth/companyLocation'],
      mapZoom: this.$store.getters['auth/loggedUser'].id !== 1 ? 13 : 7,
      addressSearch: {
        addressSearchField: '',
        addressSearchResults: [],
        addressPin: null
      },
      tilesUrl: 'https://light.ro.maps.ogo.taxi/tile/{z}/{x}/{y}.png',
      useGoogleMaps: false
    }
  },
  mounted () {
    this.popupItem = this.$el
    this.getDriverPositions()
    this.getSavedPolygons()

    this.getDriverPositionsInterval = setInterval(this.getDriverPositions, 25000)
  },
  beforeDestroy () {
    clearInterval(this.getDriverPositionsInterval)
  },
  computed: {
    mapIco () {
      return mapIco
    },
    mapCenterOSM () {
      return latLng(this.$store.getters['auth/companyLocation'].lat, this.$store.getters['auth/companyLocation'].lng)
    },
    polygonsGoogleList () {
      return this.polygonsRawList.map((item) => {
        const itemNew = Object.assign({}, item)
        itemNew.paths = []
        item.paths.forEach((it) => {
          itemNew.paths.push(new window.google.maps.LatLng(it.lat, it.long))
        })
        return itemNew
      })
    },
    driversLocationsFiltered () {
      if (this.markerSearch) {
        return this.driversLocations.filter((item) => {
          return item.indicativ === this.markerSearch
        })
      }
      return this.driversLocations
    }
  },
  methods: {
    isBanned (m) {
      return m.ban_until && dayjs(m.ban_until) > dayjs()
    },
    extractPolygonPaths (polygon) {
      return polygon.paths.map(item => [item.lat, item.long])
    },
    createLatLng (lat, lng) {
      return latLng(lat, lng)
    },
    clearAddressSearch () {
      this.addressSearch.addressSearchResults = []
      this.addressSearch.addressSearchField = ''
      this.addressSearch.addressPin = null
      this.mapCenter = this.$store.getters['auth/companyLocation']
      this.mapZoom = this.$store.getters['auth/loggedUser'].id !== 1 ? 13 : 7
    },
    formatName (place) {
      const parts = [
        place.label
      ]
      if (place.bloc) {
        parts.push('BLOC: ' + place.bloc)
      }
      if (place.scara) {
        parts.push('SCARA: ' + place.scara)
      }
      if (place.reper) {
        parts.push('REPER: ' + place.reper)
      }

      return parts.join(' / ')
    },
    onAddressSearchSelected (address) {
      console.log(address)
      if (address.item.isInternal) {
        this.addressSearch.addressPin = { lat: address.item.lat, lng: address.item.lng }
        this.mapCenter = { lat: address.item.lat, lng: address.item.lng }
        this.mapZoom = 16
      } else {
        this.$axios.get(config.addrApiUrl + '/place-details?place_id=' + address.item.id + '&fields=geometry,name&language=ro')
          .then((response) => {
            this.addressSearch.addressPin = response.data.result.geometry.location
            this.mapCenter = response.data.result.geometry.location
            this.mapZoom = 16
          })
      }
    },
    onAddressSearch (search) {
      if (search.length > 2) {
        this.apiAddressSearch(search, this)
      }
    },
    apiAddressSearch: _.debounce((search, vm) => {
      vm.$axios.get(config.addrApiUrl + '/places-search?input=' + search + '&language=ro&components=country:ro&location=' + store.getters['auth/companyLocation'].lat + ',' + store.getters['auth/companyLocation'].lng + '&radius=50000&strictbounds')
        .then((response) => {
          vm.addressSearch.addressSearchResults = response.data.predictions.map((item) => {
            return {
              id: item.place_id,
              label: item.description,
              isInternal: typeof item.is_internal !== 'undefined',
              lat: item.lat,
              lng: item.lng,
              comment: item.comment,
              bloc: item.bloc ?? null,
              scara: item.scara ?? null,
              reper: item.reper ?? null
            }
          })
        })
    }, 500),
    showPolygonDesc (event, name) {
      console.log(event)
      const contentString = name
      this.infoWindowPos = event.latLng
      this.infoContent = contentString
      this.infoWinOpen = true
    },
    togglePoly () {
      this.showPoly = !this.showPoly
    },
    getSavedPolygons () {
      return Vue.axios.get(config.baseApiUrl + '/api/polygons')
        .then((response) => {
          this.polygonsRawList = response.data.data.map((polygon) => {
            polygon.isVisible = true
            return polygon
          })
        })
    },
    getDriverOrders (driverId) {
      this.$axios.get(config.baseApiUrl + '/api/orders?driver_id=' + driverId + '&state=assigned')
        .then(response => {
          this.driverHistory = response.data.data
        })
    },
    toggleDriverInfo () {
      this.showDriverInfo = true
    },
    hideDriverInfo () {
      this.showDriverInfo = false
    },
    getDriverPositions () {
      this.$axios.get(config.baseApiUrl + '/api/drivers-positions')
        .then(response => {
          this.driversLocations = response.data
        })
    },
    getDriverData (driverId) {
      this.$axios.get(config.baseApiUrl + '/api/users/' + driverId)
        .then(response => {
          this.Sofer = response.data
        })
    },
    driverInfo (driverId) {
      this.showDriverInfo = true
      this.getDriverData(driverId)
      this.getDriverOrders(driverId)
    },
    markerIcon (data) {
      if (data.is_busy) {
        return {
          url: mapIcoBusy,
          strokeColor: '#ffffff',
          scaledSize: { width: 26, height: 26 },
          labelOrigin: { x: 13, y: 13 }
        }
      }
      if (data.ban_until && dayjs(data.ban_until) > dayjs()) {
        return {
          url: mapIcoPaused,
          strokeColor: '#ffffff',
          scaledSize: { width: 26, height: 26 },
          labelOrigin: { x: 13, y: 13 }
        }
      }
      if (data.is_paused) {
        return {
          url: mapIcoPaused,
          strokeColor: '#ffffff',
          scaledSize: { width: 26, height: 26 },
          labelOrigin: { x: 13, y: 13 }
        }
      }
      return {
        url: mapIcoFree,
        strokeColor: '#ffffff',
        scaledSize: { width: 26, height: 26 },
        labelOrigin: { x: 13, y: 13 }
      }
    },
    openMessages () {
      this.$store.state.messages.isOpen = true
      this.$store.dispatch('messages/fetchConversations').then(() => {
        setTimeout(() => {
          this.$store.commit('messages/addNewConversation', {
            conversation: {
              last_message_at: null,
              interlocutor_id: this.Sofer.id,
              name: this.Sofer.name,
              phone: this.Sofer.phone,
              email: this.Sofer.email,
              has_unread: false,
              unread_count: 0
            }
          })
        }, 500)
      })
    },
    addPriority (hours) {
      const from = dayjs().format('YYYY-MM-DD HH:mm:ss')
      const to = dayjs().add(hours, 'hours').format('YYYY-MM-DD HH:mm:ss')
      this.$axios.post(config.baseApiUrl + '/api/users-intervals?quick=1', {
        from: from,
        to: to,
        users: [
          { id: this.Sofer.id, indicativ: this.Sofer.identifier }
        ]
      }).then(response => {
        this.Sofer.high_priority = true
        this.$toasted.success('Salvat', {
          duration: 2000
        })
      })
    },
    removePriority () {
      this.$axios.delete(config.baseApiUrl + '/api/users-intervals/delete-for-user/' + this.Sofer.id)
        .then(() => {
          this.Sofer.high_priority = false
        })
    }
  },
  watch: {
    driversLocationsFiltered: function (results) {
      if (results.length === 1) {
        this.mapCenter.lat = results[0].lat
        this.mapCenter.long = results[0].long
      }
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style>
  .priority-dropdown .dropdown-toggle {
    padding: 0 2px;
  }
</style>
