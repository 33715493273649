/**
 * @param {string} phoneNumber
 * @returns {string}
 */
function format (phoneNumber) {
  /* if (phoneNumber.charAt(0) === '0' && phoneNumber.charAt(1) === '0') {
    // international phone format
    phoneNumber = '+' + parseInt(phoneNumber)
  } else {
    // RO phone format
    phoneNumber = '+4' + phoneNumber
  } */
  return phoneNumber
}

/**
 * @param {string} phoneNumber
 * @returns {string}
 */
function formatForDisplay (phoneNumber) {
  // phoneNumber = phoneNumber.replace('+4+40', '+40')
  // phoneNumber = phoneNumber.replace('+40', '0')
  return phoneNumber
}

export {
  format,
  formatForDisplay
}
